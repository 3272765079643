<template>
  <div style="background-color: #9f978a">
    <div class="calculator-page">
      <div class="header-text">基本報價計算機</div>

      <div class="container">
        <div class="row" style="padding-bottom: 55px">
          <div
            class="sub-header-text offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            單位資料
          </div>
          <div class="col-12">
            <div class="sub-header-line"></div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">實用面積</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="size"
                >
                  <option value="" selected></option>
                  <option value="150">200尺以下</option>
                  <option value="250">201-300尺</option>
                  <option value="350">301-400尺</option>
                  <option value="450">401-500尺</option>
                  <option value="550">501-600尺</option>
                  <option value="650">601-700尺</option>
                  <option value="750">701-800尺</option>
                  <option value="850">801-900尺</option>
                  <option value="950">901-1000尺</option>
                  <option value="1050">1001-1100尺</option>
                  <option value="1150">1101-1200尺</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">房間數量</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="numOfRoom"
                >
                  <option value="" selected></option>
                  <option value="1">1間</option>
                  <option value="2">2間</option>
                  <option value="3">3間</option>
                  <option value="4">4間</option>
                  <option value="5">5間</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">廁所數量</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="numOfWashroom"
                >
                  <option value="" selected></option>
                  <option value="1">1間</option>
                  <option value="2">2間</option>
                </select>
              </div>
              <div class="col-3">&nbsp;</div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">廚房數量</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="numOfKitchen"
                >
                  <option value="" selected></option>
                  <option value="1">1間</option>
                  <option value="2">2間</option>
                </select>
              </div>
              <div class="col-3">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 55px">
          <div
            class="sub-header-text offset-1 col-11 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            清拆項目
          </div>
          <div class="col-12">
            <div class="sub-header-line"></div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">磁磚、地板、地腳線</div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="dis1" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">廚櫃、廚房工作檯</div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="dis2" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">
                洗手間面盤、座廁、浴缸
              </div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="dis3" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">門連門框</div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="dis4" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 55px">
          <div
            class="sub-header-text offset-1 col-11 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            水電工程
          </div>
          <div class="col-12">
            <div class="sub-header-line"></div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">廚房新造來去水位</div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="we1" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">廁所新造來去水位</div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="we2" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">更換電錶箱</div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="we3" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 55px">
          <div
            class="sub-header-text offset-1 col-11 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            泥水工程
          </div>
          <div class="col-12">
            <div class="sub-header-line"></div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">
                廚房牆身及地台面鋪磁磚
              </div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="lw1" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">
                廁所牆身及地台面鋪磁磚
              </div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="lw2" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">
                全屋地台面鋪磁磚連磚腳線
              </div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="lw3" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 55px">
          <div
            class="sub-header-text offset-1 col-11 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            油漆工程
          </div>
          <div class="col-12">
            <div class="sub-header-line"></div>
          </div>

          <div
            class="checkbox-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="checkbox-field-text col-8">
                全屋牆身及天花剷底批灰，面做立邦五合一防潮乳膠漆
              </div>
              <div class="checkbox-inner-wrapper col-4">
                <label class="calc-checkbox-container check-box-container">
                  <input type="checkbox" v-model="oil1" />
                  <span class="tick-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 55px">
          <div
            class="sub-header-text offset-1 col-11 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            木工 (訂造傢俬)
          </div>
          <div class="col-12">
            <div class="sub-header-line"></div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">高櫃 (96"高)</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood3"
                >
                  <option value="" selected></option>
                  <option value="1">48寸闊</option>
                  <option value="2">96寸闊</option>
                  <option value="3">144寸闊</option>
                  <option value="4">192寸闊</option>
                  <option value="5">240寸闊</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">半腰櫃 (42"高)</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood4"
                >
                  <option value="" selected></option>
                  <option value="1">48寸闊</option>
                  <option value="2">96寸闊</option>
                  <option value="3">144寸闊</option>
                  <option value="4">192寸闊</option>
                  <option value="5">240寸闊</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">電視櫃 (24"高)</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood5"
                >
                  <option value="" selected></option>
                  <option value="1">48寸闊</option>
                  <option value="2">96寸闊</option>
                  <option value="3">144寸闊</option>
                  <option value="4">192寸闊</option>
                  <option value="5">240寸闊</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">
                書枱 / 工作枱 (30"高)
              </div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood8"
                >
                  <option value="" selected></option>
                  <option value="1">36寸闊</option>
                  <option value="2">72寸闊</option>
                  <option value="3">108寸闊</option>
                  <option value="4">144寸闊</option>
                  <option value="5">180寸闊</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">化妝枱連鏡 (30"高)</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood9"
                >
                  <option value="" selected></option>
                  <option value="1">36寸闊</option>
                  <option value="2">72寸闊</option>
                  <option value="3">108寸闊</option>
                  <option value="4">144寸闊</option>
                  <option value="5">180寸闊</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">單人床 (36")</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood7"
                >
                  <option value="" selected></option>
                  <option value="1">1張</option>
                  <option value="2">2張</option>
                  <option value="3">3張</option>
                  <option value="4">4張</option>
                  <option value="5">5張</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">雙人床 (54")</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood6"
                >
                  <option value="" selected></option>
                  <option value="1">1張</option>
                  <option value="2">2張</option>
                  <option value="3">3張</option>
                  <option value="4">4張</option>
                  <option value="5">5張</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">廚櫃連工作檯</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood1"
                >
                  <option value="" selected></option>
                  <option value="1">36寸闊</option>
                  <option value="2">72寸闊</option>
                  <option value="3">108寸闊</option>
                  <option value="4">144寸闊</option>
                  <option value="5">180寸闊</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-5">浴室鏡櫃連地櫃</div>
              <div class="col-7" style="padding: 0">
                <select
                  class="form-select form-item-custom-style"
                  v-model="wood2"
                >
                  <option value="" selected></option>
                  <option value="1">36寸闊</option>
                  <option value="2">72寸闊</option>
                  <option value="3">108寸闊</option>
                  <option value="4">144寸闊</option>
                  <option value="5">180寸闊</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 20px">
          <div class="col-12" style="padding-bottom: 10px; font-size: 0.9em">
            <div class="remark-wrapper">
              備註：<br />
              装修：物料由客方選購，例如磁磚、門、地板、牆紙、潔具、層架、電器、燈飾、鎖。
              訂制家俬及廚廁櫃：板材為木夾板，面做耐磨料(生態板)及防火膠板。
              五金配件：全屋掩門緩沖門鉸及選用三節路軌。
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 55px">
          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-4">姓名*</div>
              <div class="col-8" style="padding: 0">
                <input
                  type="text"
                  class="form-control form-item-custom-style"
                  v-model="enquiryName"
                />
              </div>
              <div class="col-3">&nbsp;</div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-4">電話*</div>
              <div class="col-8" style="padding: 0">
                <input
                  type="text"
                  class="form-control form-item-custom-style"
                  v-model="enquiryPhone"
                />
              </div>
              <div class="col-3">&nbsp;</div>
            </div>
          </div>

          <div
            class="drop-down-field-wrapper offset-1 col-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6"
          >
            <div class="row" style="padding: 0">
              <div class="drop-down-field-text col-4">電郵地址*</div>
              <div class="col-8" style="padding: 0">
                <input
                  type="text"
                  class="form-control form-item-custom-style"
                  v-model="enquiryEmail"
                />
              </div>
              <div class="col-3">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 20px">
          <div class="col-12" style="padding-bottom: 10px; font-size: 0.9em">
            <div class="remark-wrapper">
              *必須填寫才能提交<br /><br />
              私隱政策聲明：我們承諾遵守《個人資料（私隱）條例》
              下有關管理個人資料的規定，保障我們所持有的個人資料的私隱、保密性及安全。我們同樣承諾確保我們所有僱員及代理堅守這些責任。
              <br /><br />
              以上基本報價計算機只供簡單版資訊，由於裝修及訂制傢俬項目繁多，以上報價僅供參考，不能作準，如需更多資訊，可聯絡本公司。
            </div>
          </div>
        </div>

        <!-- <div class="row" style="padding-top: 20px; padding-bottom: 50px">
          <div class="col-12" style="text-align: center">
            <button class="form-button" v-on:click="submitEnquiryForm">
              提交
            </button>
          </div>
        </div> -->

        <div class="row" style="padding-top: 20px; padding-bottom: 10px">
          <div class="col-12" style="padding-bottom: 10px">
            <div class="block-button-wrapper">
              <div class="block-button" v-on:click="submitEnquiryForm">
                獲取免費報價
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="total-wrapper">
        <div class="row">
          <div class="label col-6">裝修及傢俬項目共計</div>
          <div class="price col-6">
            ${{
              enquiryBudget.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { SUBMIT_ENQUIRY_CALCULATOR_API_PATH } from "../constants";

export default {
  name: "Calculator",
  methods: {
    submitEnquiryForm: function () {
      let that = this;

      let emailTrim = this.enquiryEmail.trim();

      let isValidName = this.enquiryName.trim() != "";
      let isValidEmail =
        emailTrim != "" &&
        emailTrim.indexOf("@") != -1 &&
        emailTrim.indexOf(".") != -1;
      let isValidPhone = this.enquiryPhone.trim() != "";

      if (isValidName && isValidEmail && isValidPhone) {
        that.enquiry();
        let sizeText = "";

        switch (this.size) {
          case "150":
            sizeText = "200尺以下";
            break;

          case "250":
            sizeText = "201-300尺";
            break;

          case "350":
            sizeText = "301-400尺";
            break;

          case "450":
            sizeText = "401-500尺";
            break;

          case "550":
            sizeText = "501-600尺";
            break;

          case "650":
            sizeText = "601-700尺";
            break;

          case "750":
            sizeText = "701-800尺";
            break;

          case "850":
            sizeText = "801-900尺";
            break;

          case "950":
            sizeText = "901-1000尺";
            break;

          case "1050":
            sizeText = "1001-1100尺";
            break;

          case "1150":
            sizeText = "1101-1200尺";
            break;

          default:
            sizeText = "N/A";
        }

        let postData = {
          enquiryName: that.enquiryName,
          enquiryPhone: that.enquiryPhone,
          enquiryEmail: that.enquiryEmail,

          size: sizeText,
          numOfRoom: that.numOfRoom ? that.numOfRoom : "N/A",
          numOfWashroom: that.numOfWashroom ? that.numOfWashroom : "N/A",
          numOfKitchen: that.numOfKitchen ? that.numOfKitchen : "N/A",

          dis1: that.dis1 ? "1項" : null,
          dis2: that.dis2 ? "1項" : null,
          dis3: that.dis3 ? "1項" : null,
          dis4: that.dis4 ? "1項" : null,

          we1: that.we1 ? "1項" : null,
          we2: that.we2 ? "1項" : null,
          we3: that.we3 ? "1項" : null,

          lw1: that.lw1 ? "1項" : null,
          lw2: that.lw2 ? "1項" : null,
          lw3: that.lw3 ? "1項" : null,

          oil1: that.oil1 ? "1項" : null,

          wood1: that.wood1 ? that.wood1 : null,
          wood2: that.wood2 ? that.wood2 : null,
          wood3: that.wood3 ? that.wood3 : null,
          wood4: that.wood4 ? that.wood4 : null,
          wood5: that.wood5 ? that.wood5 : null,
          wood6: that.wood6 ? that.wood6 : null,
          wood7: that.wood7 ? that.wood7 : null,
          wood8: that.wood8 ? that.wood8 : null,
          wood9: that.wood9 ? that.wood9 : null,

          enquiryBudget:
            "$" +
            that.enquiryBudget.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }),
        };

        axios
          .post(SUBMIT_ENQUIRY_CALCULATOR_API_PATH, postData)
          .then((res) => {
            if (res.data.result) {
              alert("您的查詢已經發出。");
            } else alert("發生問題，請稍後再試");
          })
          .catch((error) => alert("發生問題，請稍後再試"));
      } else alert("請輸入姓名，電話及有效的電郵。");
    },
    enquiry: function () {
      let totalPrice = 0;

      let calcSize =
        this.size == null || this.size == "" ? 0 : parseInt(this.size);

      let calcNumOfRoom =
        this.numOfRoom == null || this.numOfRoom == ""
          ? 0
          : parseInt(this.numOfRoom);

      let calcNumOfWashroom =
        this.numOfWashroom == null || this.numOfWashroom == ""
          ? 0
          : parseInt(this.numOfWashroom);

      let calcNumOfKitchen =
        this.numOfKitchen == null || this.numOfKitchen == ""
          ? 0
          : parseInt(this.numOfKitchen);

      let calcWood1 =
        this.wood1 == null || this.wood1 == "" ? 0 : parseInt(this.wood1);
      let calcWood2 =
        this.wood2 == null || this.wood2 == "" ? 0 : parseInt(this.wood2);
      let calcWood3 =
        this.wood3 == null || this.wood3 == "" ? 0 : parseInt(this.wood3);
      let calcWood4 =
        this.wood4 == null || this.wood4 == "" ? 0 : parseInt(this.wood4);
      let calcWood5 =
        this.wood5 == null || this.wood5 == "" ? 0 : parseInt(this.wood5);
      let calcWood6 =
        this.wood6 == null || this.wood6 == "" ? 0 : parseInt(this.wood6);
      let calcWood7 =
        this.wood7 == null || this.wood7 == "" ? 0 : parseInt(this.wood7);
      let calcWood8 =
        this.wood8 == null || this.wood8 == "" ? 0 : parseInt(this.wood8);
      let calcWood9 =
        this.wood9 == null || this.wood9 == "" ? 0 : parseInt(this.wood9);

      // -- 清拆 Start --
      if (this.dis1) totalPrice += 26 * calcSize;

      if (this.dis2) totalPrice += 5280 * calcNumOfKitchen;

      if (this.dis3) totalPrice += 3850 * calcNumOfWashroom;

      if (this.dis4)
        totalPrice +=
          550 * (calcNumOfRoom + calcNumOfWashroom + calcNumOfKitchen);
      // -- 清拆 End --

      // -- 水電 Start --
      if (this.we1) totalPrice += 6600 * calcNumOfKitchen;

      if (this.we2) totalPrice += 13200 * calcNumOfWashroom;

      if (this.we3)
        totalPrice +=
          8800 * (calcNumOfRoom + calcNumOfWashroom + calcNumOfKitchen);
      // -- 水電 End --

      // -- 泥水 Start --
      if (this.lw1) totalPrice += 24200 * calcNumOfKitchen;

      if (this.lw2) totalPrice += 19800 * calcNumOfWashroom;

      if (this.lw3) totalPrice += 66 * calcSize;
      // -- 泥水 End --

      // -- 油漆 Start --
      if (this.oil1) totalPrice += 108 * calcSize;
      // -- 油漆 End --

      // -- 木工 Start --
      totalPrice += 10065 * calcWood1;

      totalPrice += 16170 * calcWood2;

      totalPrice += 13640 * calcWood3;

      totalPrice += 9240 * calcWood4;

      totalPrice += 7920 * calcWood5;

      totalPrice += 9900 * calcWood6;

      totalPrice += 7900 * calcWood7;

      totalPrice += 5940 * calcWood8;

      totalPrice += 9800 * calcWood9;
      // -- 木工 End --

      this.enquiryBudget = totalPrice.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });

      window.scrollTo(0, document.body.scrollHeight);
    },
  },
  created: async function () {},
  data() {
    return {
      enquiryName: "",
      enquiryPhone: "",
      enquiryEmail: "",
      enquiryBudget: 0,
      size: null,
      numOfRoom: null,
      numOfWashroom: null,
      numOfKitchen: null,
      dis1: false,
      dis2: false,
      dis3: false,
      dis4: false,
      we1: false,
      we2: false,
      we3: false,
      lw1: false,
      lw2: false,
      lw3: false,
      oil1: false,
      wood1: null,
      wood2: null,
      wood3: null,
      wood4: null,
      wood5: null,
      wood6: null,
      wood7: null,
      wood8: null,
      wood9: null,
    };
  },
};
</script>
